import Image from "next/image";
import Link from "next/link";

const Actions = () => (
  <section
    id="actions"
    className="space-y-8 py-12 px-6 md:px-12 grid grid-cols-1 md:grid-cols-2 justify-items-start items-center bg-white"
  >
    <div className="flex flex-col space-y-6 w-full items-center	">
      <div className="flex py-12 flex-col space-y-12 w-10/12 text-center md:text-left md:w-3/5 items-center md:items-start md:ml-20 text-black">
        <h4 className="w-full pb-3 leading-tight text-left">
          Need a Business <br />
          Loan?
        </h4>
        <p className="m-0 w-100 md:w-3/3 margin-fix text-[21px] text-left">
          We’ve got you covered! Fill out this form and also sign <br />
          up for a Data Mynt account to get started.
        </p>

        <div
          className={
            "flex rounded-full py-2 px-8 text-white bg-[#27282F] cursor-pointer"
          }
        >
          <Link
            href={
              "https://forms.zohopublic.com/alex218/form/LoansDataMynt/formperma/jWEBmkYoHODH05FqBQOafbGeud-9LYwzqXpoLCnl3XA"
            }
          >
            <a className={"w-full text-center text-sm"} target="_blank">
              Get Started!
            </a>
          </Link>
        </div>
      </div>
    </div>
    <div className={"relative w-full h-full"}>
      <div className="flex absolute right-0 bottom-0 hidden md:flex">
        <Image alt="Phone" width={460} height={520} src="/iPhone13.svg" />
      </div>
    </div>
  </section>
);

export default Actions;
