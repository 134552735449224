import React from "react";
import Image from "next/image";
import Link from "next/link";

const Gateways = () => (
  <section
    className={
      "relative flex space-x-6 bg-[#141518] text-white p-8 md:p-24 w-full"
    }
  >
    <div className={"flex-[3] relative space-y-12 md:w-auto w-full"}>
      <div className={"space-y-4 flex flex-col items-start"}>
        <h4>Data Mynt’s Be Your Own Bank Token</h4>
        <p>
          Our native token, $BYOB, rewards transition to Data Mynt banking{" "}
          <br />
          and payments. For example, earn rewards when you open a Data Mynt{" "}
          <br />
          account, deposit funds, make payments, repay a loan, or stake for{" "}
          <br />
          discounts on fees or better exchange rates.
        </p>

        <div className={"grid grid-cols-2 gap-4"}>
          <div
            className={
              "flex rounded-full w-full md:w-auto py-4 px-8 text-white bg-black text-white mt-6 cursor-pointer"
            }
          >
            <Link href={"https://datamynt.com/login"}>
              <p className={"w-full text-center text-sm"}>Learn More</p>
            </Link>
          </div>
        </div>
      </div>

      <div className={"grid grid-cols-1 md:grid-cols-3 gap-6"}>
        <div className={"space-y-6"}>
          <div
            className={
              "rounded-xl w-full border border-gray-50 p-4 flex flex-col justify-start items-start space-y-2"
            }
          >
            <Image
              alt={"museum"}
              src={"/museum_blue.svg"}
              width={48}
              height={48}
            />
            <p>
              Fee free banking <br />
              services on-chain for <br />
              staking
            </p>
          </div>

          <div
            className={
              "rounded-xl w-full border border-gray-50 p-4 flex flex-col justify-start items-start space-y-2"
            }
          >
            <Image alt={"museum"} src={"/id_blue.svg"} width={48} height={48} />
            <p>Tokens for referrals</p>
          </div>
        </div>

        <div className={"space-y-6"}>
          <div
            className={
              "rounded-xl w-full border border-gray-50 p-4 flex flex-col justify-start items-start space-y-2"
            }
          >
            <Image
              alt={"museum"}
              src={"/location_blue.svg"}
              width={48}
              height={48}
            />
            <p>
              Discounts at Data Mynt <br />
              merchant locations
            </p>
          </div>

          <div
            className={
              "rounded-xl w-full border border-gray-50 p-4 flex flex-col justify-start items-start space-y-2"
            }
          >
            <Image
              alt={"museum"}
              src={"/coins_blue.svg"}
              width={48}
              height={48}
            />
            <p>
              Rewards for power <br />
              customers
            </p>
          </div>
        </div>

        <div className={"flex items-center"}>
          <div
            className={
              "rounded-xl  w-full border border-gray-50 p-4 flex flex-col justify-start items-start space-y-2"
            }
          >
            <Image
              alt={"museum"}
              src={"/user_blue.svg"}
              width={48}
              height={48}
            />
            <p>
              Tokens for becoming an <br />
              Agent
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className={"relative hidden lg:flex h-[500px] flex-1"}>
      <Image
        layout="fill"
        objectFit={"contain"}
        objectPosition={"right bottom"}
        src={"/Nothing-Phone-1.svg"}
      ></Image>
    </div>
  </section>
);

export default Gateways;
