import Image from "next/image";
import Link from "next/link";

const Banking = () => (
  <section className={"bg-[#141518] px-8 py-8 pb-40"}>
    <div
      className={
        "bg-[#0051FE] rounded-[2rem] flex flex-col md:flex-row overflow-hidden"
      }
    >
      <div
        className={
          "relative hidden md:flex w-[400px] items-end overflow-hidden"
        }
      >
        <Image
          objectPosition={"bottom"}
          objectFit={"contain"}
          layout={"fill"}
          alt={"mobile_entrust"}
          src={"/mobile_2.png"}
        ></Image>
      </div>
      <div
        className={
          "flex flex-1 flex-col py-6 pr-6 pl-6 md:pl-0 items-start justify-center space-y-6"
        }
      >
        <div className={"space-y-2"}>
          <h4 className={"text-center md:text-left"}>
            5,000+ Businesses Entrust <br />
            Data Mynt with Their Banking{" "}
          </h4>
          <p className={"text-center md:text-left"}>
            Businesses of all sizes use DataMynt’s apps and APIs to open and
            fund international accounts, accept & send payments, withdraw, and
            finance their business.
          </p>
        </div>

        <div
          className={
            "flex space-x-2 w-full justify-center md:justify-start cursor-pointer"
          }
        >
          <div>
            <Link
              href={
                "https://play.google.com/store/apps/details?id=com.datamynt&hl=en&gl=US"
              }
            >
              <Image width={200} height={59} src={"/GooglePlay.svg"} />
            </Link>
          </div>
          <div>
            <Link
              href={
                "https://apps.apple.com/us/app/datamynt-point-of-sale-pos/id1572840222"
              }
            >
              <Image width={200} height={59} src={"/Appstore.svg"} />
            </Link>
          </div>
        </div>
      </div>
      <div
        className={
          "relative flex md:hidden w-full h-[200px] items-end overflow-hidden"
        }
      >
        <Image
          objectPosition={"left bottom"}
          objectFit={"contain"}
          layout={"fill"}
          alt={"mobile_entrust"}
          src={"/mobile_2.png"}
        ></Image>
      </div>
    </div>
  </section>
);

export default Banking;
