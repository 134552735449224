import Image from "next/image";

const TrackSales = () => (
  <section
    id="actions"
    className="w-full flex flex-col space-y-6 text-center text-white bg-[#141518] py-16 px-6 md:px-12 items-center justify-center"
  >
    <h4>
      Committed to unlocking opportunities for African businesses and brands
    </h4>

    <div className={"grid grid-cols-1 md:grid-cols-3 gap-6 lg:w-2/3"}>
      <div
        className={
          "bg-white space-y-2 rounded-2xl p-4 flex flex-col justify-start items-start text-left text-black"
        }
      >
        <Image width={40} height={40} src={"/blue_wallet.svg"} />
        <p>
          <b>Security</b>
          <br />
          50,000 wallets deployed holding $90b. Fully encrypted with military
          grade security that has been security audited 5x.
        </p>
      </div>

      <div
        className={
          "bg-white space-y-2 rounded-2xl p-4 flex flex-col justify-start items-start text-left text-black"
        }
      >
        <Image width={40} height={40} src={"/blue_lock.svg"} />
        <p>
          <b>Data Secured</b>
          <br />
          We protect your personal information and collect and use your
          information in accordance with legal requirements.
        </p>
      </div>

      <div
        className={
          "bg-white space-y-2 rounded-2xl p-4 flex flex-col justify-start items-start text-left text-black"
        }
      >
        <Image width={40} height={40} src={"/blue_db.svg"} />
        <p>
          <b>Secured Transactions</b>
          <br />
          Your account is protected with 2 Factor Authentication and multi-sig
          wallet.
        </p>
      </div>
    </div>
  </section>
);

export default TrackSales;
