import Image from "next/image";
import Link from "next/link";

const SellPerson = () => (
  <section
    id="actions"
    className="w-full py-12 md:py-24 space-y-6 flex flex-col items-center justify-center overflow-hidden"
  >
    <div className="flex flex-col w-full text-center items-center justify-center space-y-4 px-6">
      {/* <p>Join a Thriving community</p> */}
      <h4 className="pb-4">Join a thriving business community</h4>

      {/* <div
        className={
          "flex rounded-full py-4 px-6 text-white bg-[#27282F] mt-6 cursor-pointer"
        }
      >
        <Link href={"https://datamynt.com/agents"}>
          <p className={"text-center text-sm"}>Become an Agent Today</p>
        </Link>
      </div> */}
    </div>

    <div className={"relative h-[200px] md:h-[500px] w-[200%] md:w-[105%]"}>
      <Image layout={"fill"} objectFit={"contain"} src={"/people.svg"}></Image>
    </div>
  </section>
);

export default SellPerson;
