const FormikErrorMsgs = {
  required: "This field is required",
  invalidEmail: "This is not a valid email",
  invalidPhone: "This is not a valid phone",
  wrongPassword:
    "Password needs to be at least 8 characters long and maximum of 24 characters. It also needs to include at least one number, one special character and one uppercase letter.",
  noSpecialChars: "No special chars are allowed in this field"
};

export default FormikErrorMsgs;
