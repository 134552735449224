import React from "react";
import Image from "next/image";
const InvestorsBrands = () => (
  <section
    id="testimony"
    className="flex flex-col justify-center  items-center py-24 px-2 md:px-8"
  >
    <h4 className="text-center p-8 md:text-left">
      We’re Supported by Top Investors
    </h4>
    <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-0 w-full md:w-3/4 lg:w-3/5 m-0">
      <span className="flex items-center justify-center p-6 md:p-0">
        <Image
          width={226}
          height={48}
          objectFit="contain"
          alt="Coinbase"
          src="/coinbase.svg"
        />
      </span>
      <span className="flex items-center justify-center p-6 md:p-6">
        <Image
          width={226}
          height={48}
          objectFit="contain"
          alt="Polygon"
          src="/polygon.svg"
        />
      </span>
      <span className="flex items-center justify-center">
        <Image
          width={197}
          height={110}
          objectFit="contain"
          alt="Startup Haven"
          src="/haven.svg"
        />
      </span>
      <span className="flex items-center justify-center p-6 md:p-0">
        <Image
          width={235}
          height={32}
          objectFit="contain"
          alt="Angel List Ventures"
          src="/angelist.svg"
        />
      </span>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-4 gap-3 space-y-2  w-full md:w-3/4 lg:w-3/5">
      <span className="flex items-center justify-center p-6 md:p-0">
        <Image
          width={106}
          height={50}
          objectFit="contain"
          alt="Digifinex"
          src="/digifinex.png"
        />
      </span>
      <span className="flex items-center justify-center p-6 md:p-0">
        <Image
          width={100}
          height={100}
          objectFit="contain"
          alt="Digitata"
          src="/digitata.png"
        />
      </span>
      <span className="flex items-center justify-center p-6 md:p-0">
        <Image
          width={164}
          height={40}
          objectFit="contain"
          alt="UOB"
          src="/newUOB.jpeg"
        />
      </span>
      <span className="flex items-center justify-center p-6 md:p-0">
        <Image
          width={100}
          height={100}
          objectFit="contain"
          alt="Redbeard Ventures"
          src="/RedBeardVenturesLogoHero.png"
        />
      </span>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-4 gap-3 space-y-2  w-full md:w-3/4 lg:w-3/5 px-10">
      <span className="flex items-center justify-center p-6 md:p-0">
        <Image
          width={226}
          height={48}
          objectFit="contain"
          alt="ATX Venture Partners"
          src="/atx.png"
        />
      </span>
      <span className="flex items-center justify-center p-6 md:p-6">
        <Image
          width={226}
          height={48}
          objectFit="contain"
          alt="Gaingels"
          src="/gaingels.png"
        />
      </span>
      <span className="flex items-center justify-center">
        <Image
          width={132}
          height={58}
          objectFit="contain"
          alt="Signum"
          src="/signum.png"
        />
      </span>
      <span className="flex items-center justify-center p-6 md:p-0">
        <Image
          width={112}
          height={112}
          objectFit="contain"
          alt="Unicorn Hunter"
          src="/latestInvestor.png"
        />
      </span>
    </div>
  </section>
);
export default InvestorsBrands;
