import Image from "next/image";
import isTailwindBreakpoint from "../../helpers/twBreakpoints";

const CryptoCheckout = () => (
  <section
    id="actions"
    className="w-full bg-[#141518] text-white flex flex-col space-y-12 md:space-y-24 justify-center items-center p-8 md:p-24"
  >
    <div className="flex flex-col space-y-4 text-center">
      <h4>Products and Features</h4>

      <p>
        {/* Payments, multicurrency accounts, and business loans. <br /> */}
        No transaction declines. No delays. Available 24 hours a day, 7 days a
        week.
      </p>
    </div>

    <div
      className={
        "flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-12 items-center justify-center"
      }
    >
      <div
        className={
          "hidden md:flex flex-col flex-[0.9] justify-center items-center md:items-end"
        }
      >
        <div className={"space-y-4"}>
          <h5>USD or EUR Account</h5>
          <p className={"text-sm"}>
            Make payments in USD or EUR. No limits.
            <br />
            Deposit or withdrawal using your mobile
            <br />
            money or bank account.
          </p>
        </div>
      </div>

      <div className={"flex justify-start w-full md:w-auto md:flex-[1.1]"}>
        <div className={"relative h-[225px] md:h-[455px] w-full"}>
          <Image
            objectPosition={
              isTailwindBreakpoint("md") ? "left bottom" : "left center"
            }
            layout={"fill"}
            objectFit={"contain"}
            src={"/Transaction_landing.svg"}
          ></Image>
        </div>
      </div>

      <div className={"flex md:hidden flex-col w-full justify-center"}>
        <div className={"space-y-4"}>
          <h5>Multicurrency Account</h5>
          <p className={"text-sm"}>
            Optimize your business’s purchasing <br /> power by keeping your
            assets in USD, <br /> EUR or GBP. Deposit and withdrawal <br />{" "}
            using your mobile money or bank account.
          </p>
        </div>
      </div>
    </div>

    <div
      className={
        "relative flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-12 items-center justify-center"
      }
    >
      <div className={"flex w-full md:w-auto md:flex-[1.1]"}>
        <div className={"relative h-[225px] md:h-[455px] w-full"}>
          <Image
            objectPosition={
              isTailwindBreakpoint("md") ? "left bottom" : "left center"
            }
            layout={"fill"}
            objectFit={"contain"}
            src={"/Checkout_landing.svg"}
          ></Image>
        </div>
      </div>

      <div className={"flex flex-[0.9] flex-col w-full justify-center"}>
        <div className={"space-y-4"}>
          <h5>Cross-Border Payments</h5>
          <p className={"text-sm"}>
            Global instant payments with full <br />
            transparency on your <br /> receivers blockchain of choice. <br />{" "}
            Use invoicing to get paid by <br />
            anyone anywhere 24/7
          </p>
        </div>
      </div>
    </div>

    <div
      className={
        "flex flex-col md:flex-row w-full space-y-4 md:space-y-0 items-center justify-center"
      }
    >
      <div
        className={
          "hidden md:flex flex-col flex-[0.9] justify-center items-center md:items-end"
        }
      >
        <div className={"space-y-4"}>
          <h5>
            Point of Sale and Online <br />
            Checkout
          </h5>
          <p className={"text-sm"}>
            Accept payments from your customers using any <br /> asset on any
            blockchain as USD or your local <br /> currency. Mobile app point of
            sale, API or <br /> checkout page integration options.
          </p>
        </div>
      </div>

      <div className={"flex justify-start w-full md:w-auto md:flex-[1.1]"}>
        <div className={"relative h-[225px] md:h-[455px] w-full"}>
          <Image
            objectPosition={
              isTailwindBreakpoint("md") ? "left bottom" : "left center"
            }
            layout={"fill"}
            objectFit={"contain"}
            src={"/GetPaid_landing.svg"}
          ></Image>
        </div>
      </div>

      <div className={"flex md:hidden flex-col w-full justify-center"}>
        <div className={"space-y-4"}>
          <h5>Multicurrency Account</h5>
          <p className={"text-sm"}>
            Optimize your business’s purchasing <br /> power by keeping your
            assets in USD, <br /> EUR or GBP. Deposit and withdrawal <br />{" "}
            using your mobile money or bank account.
          </p>
        </div>
      </div>
    </div>

    <div
      className={
        "relative flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-12 items-center justify-center"
      }
    >
      <div className={"flex w-full md:w-auto md:flex-[1.1]"}>
        <div className={"relative h-[225px] md:h-[455px] w-full"}>
          <Image
            objectPosition={
              isTailwindBreakpoint("md") ? "left bottom" : "left center"
            }
            layout={"fill"}
            objectFit={"contain"}
            src={"/Loans_landing.svg"}
          ></Image>
        </div>
      </div>

      <div className={"flex flex-[0.9] flex-col w-full justify-center"}>
        <div className={"space-y-4"}>
          <h5>COMING SOON: Business Loans</h5>
          <p className={"text-sm"}>Finance your business with Data Mynt.</p>
        </div>
      </div>
    </div>
  </section>
);

export default CryptoCheckout;
