import PropTypes from "prop-types";
import Image from "next/image";
import AppStoreButton from "./AppStoreButton";
import isTailwindBreakpoint from "../../helpers/twBreakpoints";
import { useEffect, useRef, useState } from "react";
import Link from "next/link";

export default function Header() {
  const bannerRef = useRef();
  const [bannerHeight, setBannerHeight] = useState();

  useEffect(() => {
    const resetBannerHeight = () => {
      setBannerHeight(bannerRef.current?.offsetHeight);
    };

    window.addEventListener("resize", resetBannerHeight);

    return () => window.removeEventListener("resize", resetBannerHeight);
  }, []);

  useEffect(() => {
    setBannerHeight(bannerRef.current?.offsetHeight);
  }, []);

  return (
    <section className="bg-[#141518] flex flex-col md:flex-row relative w-full text-white pt-[60px] md:pt-[150px] lg:pt-[180px] pb-[50px] items-center md:items-start">
      <div className={"hidden md:flex absolute top-0 left-0"}>
        <HeaderBgSVGComponent />
      </div>

      <div className={"md:hidden flex absolute top-0 left-0"}>
        <HeaderBgSVGComponent width={270} height={300} />
      </div>

      <div
        className={
          "flex w-full md:w-auto md:flex-1 flex-col md:pl-12 lg:pl-24 items-start justify-start"
        }
      >
        <div className="px-4 md:px-0 flex flex-col space-y-4 items-center text-center md:text-left w-full md:w-auto">
          <h5 className="text-4xl md:text-[50px] lg:text-[64px] leading-[2.5rem] md:leading-[3.5rem] lg:leading-[4.5rem] w-full font-bold">
            Global Payments <br />
            and Banking for <br /> Africa
          </h5>

          <p
            className={
              "w-full text-lg md:text-xl lg:text-2xl text-gray-10 font-normal font-light"
            }
          >
            Low cost, high speed international payments and invoicing in a
            single solution
          </p>
        </div>

        <div
          className={
            "relative flex md:hidden mt-6 w-full justify-center items-center"
          }
        >
          <div className={"flex w-full justify-end"}>
            <Image
              width={560}
              height={520}
              alt={"header_image"}
              src={"/rectangles_mobile.png"}
            />
          </div>

          <div
            className={
              "flex absolute md:hidden w-[80%] min-h-[400px] items-center justify-center"
            }
          >
            <Image
              objectFit={"contain"}
              layout={"fill"}
              alt={"header_image"}
              src={"/iphones.png"}
            />
          </div>
        </div>

        <div
          className={
            "w-full px-4 md:px-0 flex space-x-4 justify-center md:justify-start items-center mt-12"
          }
        >
          <HouseSvgComponent />
          <p className={"text-xs md:text-sm"}>
            Open an all-in-one business account. No minimum balance. 3% APY.
            <br />
            No hidden fees.
          </p>
          <ArrowRightSVGComponent />
        </div>

        <div className={"px-4 md:px-0 w-full grid-cols-2 grid gap-4"}>
          <div
            className={
              "flex rounded-full w-full md:w-auto py-6 px-8 text-black bg-white mt-6 cursor-pointer"
            }
          >
            <Link href={"https://zfrmz.com/bukVz5q3SzM18DSnC4PM"}>
              <a
                target="_blank"
                rel="noreferrer"
                className={"w-full text-center text-sm font-bold "}
              >
                Business Sign Up
              </a>
            </Link>
          </div>
          <div
            className={
              "flex rounded-full w-full md:w-auto py-6 px-8 text-white bg-[#27282F] mt-6 cursor-pointer font-bold"
            }
          >
            <Link href={"https://datamynt.com/login"}>
              <a
                target="_blank"
                rel="noreferrer"
                className={"w-full text-center text-sm "}
              >
                Business Log in
              </a>
            </Link>
          </div>
        </div>
      </div>

      <div className={"relative hidden md:flex h-[700px] flex-1 justify-end"}>
        <Image
          objectFit={"contain"}
          layout={"fill"}
          alt={"header_image"}
          src={"/Bg_Phone.png"}
        ></Image>
      </div>
    </section>
  );
}

Header.propTypes = {
  handler: PropTypes.func
};

Header.defaultProps = {
  handler: null
};

const ArrowRightSVGComponent = props => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.94 13.78a.495.495 0 0 1-.354-.147.503.503 0 0 1 0-.706L9.933 8.58a.82.82 0 0 0 0-1.16L5.586 3.073a.503.503 0 0 1 0-.706.503.503 0 0 1 .707 0l4.347 4.346c.34.34.533.8.533 1.287 0 .487-.187.947-.533 1.287l-4.347 4.346a.524.524 0 0 1-.353.147Z"
      fill="#fff"
      fillOpacity={0.6}
    />
  </svg>
);

const HouseSvgComponent = props => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.664 16v8a5.332 5.332 0 0 1-5.333 5.333H10.664a5.332 5.332 0 0 1-5.333-5.334v-8c0-.733.6-1.333 1.333-1.333h2.627c.733 0 1.333.6 1.333 1.333v4.187c0 .987.547 1.893 1.414 2.36a2.618 2.618 0 0 0 2.707-.12l1.266-.827 1.173.787a2.628 2.628 0 0 0 2.72.147 2.657 2.657 0 0 0 1.427-2.36v-4.174c0-.733.6-1.333 1.333-1.333h2.667c.733 0 1.333.6 1.333 1.333Zm2-6.667v1.333c0 1.467-.706 2.667-2.666 2.667h-20c-2.04 0-2.667-1.2-2.667-2.667V9.333c0-1.467.627-2.667 2.667-2.667h20c1.96 0 2.666 1.2 2.666 2.667Z"
      fill="#0051FE"
    />
    <path
      d="M15.518 6.667h-7.36a1.249 1.249 0 0 1 .04-1.734l1.894-1.893a1.28 1.28 0 0 1 1.8 0l3.626 3.627Zm8.311 0h-7.36l3.626-3.627a1.28 1.28 0 0 1 1.8 0l1.894 1.893c.48.48.493 1.24.04 1.734Zm-5.201 7.999c.734 0 1.334.6 1.334 1.333v4.174c0 1.066-1.187 1.706-2.067 1.106l-1.2-.8a1.332 1.332 0 0 0-1.466 0l-1.254.827c-.88.587-2.053-.053-2.053-1.107V16c0-.733.6-1.333 1.333-1.333h5.373Z"
      fill="#0051FE"
    />
  </svg>
);

const HeaderBgSVGComponent = props => (
  <svg
    width={554}
    height={494}
    viewBox="0 0 754 694"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.4}>
      <g filter="url(#a)" shapeRendering="crispEdges">
        <path
          d="M159.387 332.806a161.54 161.54 0 0 0-126.428 48.129L-75.196 491.361V197.738l247.579 56.851a161.525 161.525 0 0 0 72.717-.095l206.638-48.019a74.624 74.624 0 0 1 66.454 16.899c53.202 47.265 16.344 135.095-54.655 130.238l-304.15-20.806Z"
          fill="url(#b)"
          fillOpacity={0.5}
        />
        <path
          d="M159.415 332.387a161.95 161.95 0 0 0-126.756 48.254l-107.434 109.69V198.266l247.064 56.733a161.955 161.955 0 0 0 72.906-.095l206.638-48.02a74.206 74.206 0 0 1 66.079 16.804c52.903 46.999 16.253 134.334-54.346 129.504l-304.151-20.805Z"
          stroke="url(#c)"
          strokeOpacity={0.5}
          strokeWidth={0.841}
        />
      </g>
      <g filter="url(#d)" shapeRendering="crispEdges">
        <path
          d="M-121.269 487.736a150.99 150.99 0 0 0-118.174 44.987l-111.558 113.9V352.999l229.073 80.455a161.535 161.535 0 0 0 146.43-20.259l70.145-49.313c27.144-19.082 63.401-18.858 90.307.558 63.47 45.801 27.231 146.106-50.857 140.765l-255.366-17.469Z"
          fill="url(#e)"
          fillOpacity={0.5}
        />
        <path
          d="M-121.24 487.316a151.415 151.415 0 0 0-118.504 45.113L-350.58 645.592V353.593l228.513 80.258a161.958 161.958 0 0 0 146.81-20.312l70.146-49.313c26.998-18.979 63.058-18.756 89.819.555 63.127 45.554 27.084 145.317-50.582 140.004l-255.366-17.469Z"
          stroke="url(#f)"
          strokeOpacity={0.5}
          strokeWidth={0.841}
        />
      </g>
      <g filter="url(#g)">
        <path
          d="M-109.418 103.894a161.533 161.533 0 0 1-126.427-48.129L-344.001-54.66v293.623l205.746-59.288a161.53 161.53 0 0 1 79.639-2.499l241.872 53.537a74.771 74.771 0 0 0 65.816-17.105c53.305-47.357 16.376-135.356-54.761-130.49l-303.729 20.777Z"
          fill="url(#h)"
          fillOpacity={0.3}
        />
        <path
          d="M-109.389 104.314A161.957 161.957 0 0 1-236.146 56.06L-343.58-53.63v292.033l205.209-59.133a161.955 161.955 0 0 1 79.846-2.506l241.871 53.537a74.35 74.35 0 0 0 65.447-17.009c53.005-47.09 16.283-134.594-54.453-129.755l-303.729 20.777Z"
          stroke="url(#i)"
          strokeOpacity={0.5}
          strokeWidth={0.841}
        />
      </g>
      <g filter="url(#j)">
        <path
          d="M-43.856-38.977a161.534 161.534 0 0 1-106.008-39.65L-230.775-149v263L60.317 56.216a161.533 161.533 0 0 1 55.801-1.246l360.108 54.908a74.852 74.852 0 0 0 50.357-10.152c64.462-39.45 36.501-138.703-39.074-138.703H-43.856Z"
          fill="url(#k)"
          fillOpacity={0.3}
        />
        <path
          d="M-43.856-38.556A161.954 161.954 0 0 1-150.14-78.31l-80.215-69.767v261.565l290.59-57.685a161.959 161.959 0 0 1 55.947-1.249l360.107 54.908a74.437 74.437 0 0 0 50.075-10.095c64.099-39.228 36.295-137.923-38.855-137.923H-43.856Z"
          stroke="url(#l)"
          strokeOpacity={0.5}
          strokeWidth={0.841}
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={3.048}
        y1={816.114}
        x2={573.468}
        y2={71.959}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.5} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={-86.975}
        y1={526.697}
        x2={681.158}
        y2={143.051}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="e"
        x1={-272.757}
        y1={971.376}
        x2={297.664}
        y2={227.221}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.5} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="f"
        x1={-362.779}
        y1={681.959}
        x2={405.354}
        y2={298.313}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="h"
        x1={-174.775}
        y1={91.999}
        x2={324.724}
        y2={195}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007AFF" stopOpacity={0} />
        <stop offset={1} stopColor="#007AFF" />
      </linearGradient>
      <linearGradient
        id="i"
        x1={-355.779}
        y1={-89.997}
        x2={412.354}
        y2={293.649}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007AFF" />
        <stop offset={1} stopColor="#007AFF" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="k"
        x1={-129.039}
        y1={-439.883}
        x2={307.898}
        y2={387.584}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.5} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="l"
        x1={-246.091}
        y1={-180.65}
        x2={571.853}
        y2={412.385}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <filter
        id="a"
        x={-179.521}
        y={93.413}
        width={827.295}
        height={502.273}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={52.162} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_110_4385"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={23.557} />
        <feGaussianBlur stdDeviation={11.779} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_backgroundBlur_110_4385"
          result="effect2_dropShadow_110_4385"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_110_4385"
          result="shape"
        />
      </filter>
      <filter
        id="d"
        x={-455.326}
        y={245.398}
        width={777.091}
        height={505.55}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={52.162} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_110_4385"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={23.557} />
        <feGaussianBlur stdDeviation={11.779} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_backgroundBlur_110_4385"
          result="effect2_dropShadow_110_4385"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_110_4385"
          result="shape"
        />
      </filter>
      <filter
        id="g"
        x={-448.326}
        y={-158.986}
        width={827.028}
        height={502.273}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={52.162} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_110_4385"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_110_4385"
          result="shape"
        />
      </filter>
      <filter
        id="j"
        x={-335.1}
        y={-253.325}
        width={1001.92}
        height={471.649}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={52.162} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_110_4385"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_110_4385"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
