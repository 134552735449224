import React from "react";

import Quotes from "../../public/quotes.svg";

const Testimony = () => (
  <section className="relative w-full bg-[#141518] text-white flex flex-col py-12 space-y-12 items-center justify-center px-6">
    <h4 className="md:text-center">
      What People Are <br />
      Saying
    </h4>

    <div className={"overflow-x-scroll w-full"}>
      <div className="flex space-x-6 w-[2000px]">
        {[
          {
            quote:
              "I found DataMynt through a friend. DataMynt was so quick and easy to open my account. I didn't even need to leave my shop.",
            quoteAuthor: "Henry Okoro",
            header: "Quick and easy"
          },
          {
            quote:
              "DataMynt's cross-border payments made is so easy to send international payments to our suppliers. They're reliable, fast and cheap.",
            quoteAuthor: "Amina Wanjiku",
            header: "Easy"
          },
          {
            quote:
              "I started using DataMynt's invoicing for my business and it's been a game changer. My customers from all over the world can pay us instantly in their currency of choice.",
            quoteAuthor: "Mutiu Aderemi",
            header: "Game Change"
          },
          {
            quote:
              "Instant Payments. You get paid by your customers and sed payments to suppliers globally. No Declines. No delays. Available 24 houes a day. Makes transactions seemless.",
            quoteAuthor: "Martins Chima",
            header: "Instant Payments"
          }
        ].map(q => (
          <div
            key={q.quoteAuthor}
            className="flex flex-col w-auto justify-between items-start space-y-6 p-4 rounded-[2rem] bg-[#191B1F] h-[220px]"
          >
            <div className={"space-y-2"}>
              <h6>{q.header}</h6>
              <p>{q.quote}</p>
            </div>

            <div className={"flex space-x-2 items-center"}>
              <div
                className={
                  "rounded-full bg-white w-10 h-10 flex items-center justify-center text-xl text-black"
                }
              >
                <p>{q.quoteAuthor[0]}</p>
              </div>
              <p>{q.quoteAuthor}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Testimony;
