import { useEffect, useState } from "react";
import Script from "next/script";
import Header from "../components/landing/Header";
import Actions from "../components/landing/Actions";
import Gateways from "../components/landing/Gateways";
import SellPerson from "../components/landing/SellPerson";
import CryptoCheckout from "../components/landing/CryptoCheckout";
import SettleEasy from "../components/landing/SettleEasy";
import TrackSales from "../components/landing/TrackSales";
import TopFintech from "../components/landing/TopFintech";
import Testimony from "../components/landing/Testimony";
import Banking from "../components/landing/Banking";
import Container from "../components/landing/Container";
import Modal from "../components/landing/Modal";
import HeadComponent from "../components/landing/Head";
import InvestorsBrands from "../components/landing/InvestorsBrands";
import Benefits from "../components/landing/Benefits";
import SEO from "../data/seo.json";
import Banner from "../components/landing/Banner";
import Nav from "../components/landing/Nav";

export default function Index() {
  const [openModal, setOpenModal] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState("NGN");
  useEffect(() => {
    import("react-facebook-pixel")
      .then(x => x.default)
      .then(ReactPixel => {
        ReactPixel.init("356386442619688");
        ReactPixel.pageView();
      });
  });
  const ChangeCurrency = event => {
    setCurrentCurrency(event);
  };
  return (
    <>
      <HeadComponent SEO={SEO} />
      {/* <div className={"bg-[#1E1E1E]"}>
        <Modal modalOpened={openModal} closeModal={() => setOpenModal(false)} />
      </div> */}
      <Container
        onCurrencyNaira={() => ChangeCurrency("NGN")}
        onCurrencyShelling={() => {
          if (currentCurrency === "KSH") {
            ChangeCurrency("NGN");
          }
          if (currentCurrency === "NGN") {
            ChangeCurrency("KSH");
          }
        }}
        openModal={setOpenModal}
      >
        {/* <div className="relative  bg-[#141518] pt-[20px]">
          <Banner currentCurrency={currentCurrency} />
        </div> */}
        <div className="relative bg-wrapper py-18">
          <Header />
        </div>
        <div className="bg-wrapper text-white">
          <Banking
            title="Get Paid Up to $10,000 USD / Month as an Agent or Referral Partner"
            subTitle=""
          />
        </div>
        {/* <div className="bg-wrapper pb-fix">
          <Actions />
        </div> */}
        <CryptoCheckout />
        <div className="flex items-center justify-center">
          <SellPerson />
        </div>
        <TrackSales />
        <Gateways />
        <Testimony />
        <InvestorsBrands />
      </Container>
      <Script src="https://www.googleoptimize.com/optimize.js?id=OPT-WWVFB9W"></Script>
      <Script
        id="structured-data"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FinancialService",
            name: "DataMynt",
            image: "https://datamynt.com/logo.svg",
            url: "https://datamynt.com",
            address: {
              "@type": "PostalAddress",
              streetAddress: "340 45th st",
              addressLocality: "oakland",
              addressRegion: "CA",
              postalCode: "94609",
              addressCountry: "US"
            },
            openingHoursSpecification: {
              "@type": "OpeningHoursSpecification",
              dayOfWeek: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              opens: "00:00",
              closes: "23:59"
            },
            sameAs: [
              "https://www.facebook.com/datamyntbyob/",
              "https://twitter.com/DataMynt",
              "https://www.youtube.com/channel/UCM9OEOsQ198pbUhHMgKVOyw",
              "https://www.linkedin.com/company/datamynt/"
            ]
          })
        }}
      />
      <Script id="viralLoops">
        {!(typeof window === "undefined") &&
          !(function (a, b, c, d, t) {
            var e,
              f = a.getElementsByTagName("head")[0];
            if (!a.getElementById(c)) {
              if (
                ((e = a.createElement(b)),
                (e.id = c),
                e.setAttribute("data-vrlps-ucid", d),
                e.setAttribute("data-vrlps-version", "2"),
                e.setAttribute("data-vrlps-template", t),
                (e.src =
                  "https://app.viral-loops.com/popup_assets/js/vl_load_v2.min.js"),
                window.ub)
              ) {
                (jQuery = null), ($ = null);
                var g = a.createElement(b);
                (g.src = "https://code.jquery.com/jquery-2.2.4.min.js"),
                  f.appendChild(g);
              }
              f.appendChild(e);
              var h = a.createElement("link");
              (h.rel = "stylesheet"),
                (h.type = "text/css"),
                (h.href = "https://app.viral-loops.com/static/vl-loader.css"),
                f.appendChild(h);
              var i = a.createElement("div");
              (i.id = "vl-overlay"), (i.style.display = "none");
              var j = a.createElement("div");
              (j.id = "vl-loader"),
                i.appendChild(j),
                a.addEventListener("DOMContentLoaded", function (b) {
                  a.body.appendChild(i);
                  for (
                    var c = a.getElementsByClassName("vrlps-trigger"), d = 0;
                    d < c.length;
                    d++
                  )
                    c[d].removeAttribute("href"),
                      (c[d].onclick = function () {
                        a.getElementById("vl-overlay").style.display = "block";
                      });
                  var e = a.querySelectorAll("[data-vl-widget='popupTrigger']");
                  [].forEach.call(e, function (b) {
                    var c = a.createElement("div");
                    (c.className = "vl-embedded-cta-loader"), b.appendChild(c);
                  });
                });
            }
          })(
            document,
            "script",
            "vrlps-js",
            "OuAXFUCuW38orf422ecQJhWVYaw",
            "waitlist"
          )}
      </Script>
    </>
  );
}
