import Image from "next/image";

const SettleEasy = () => (
  <section
    id="actions"
    className="pb-24 px-6 md:w-3/4 grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 justify-items-center items-center"
  >
    <Image alt="Phone" width={508} height={205} src="/settlefunds.svg" />

    <div className="flex flex-col space-y-6 w-full items-center	">
      <div className="flex flex-col space-y-4 items-center text-left">
        <h4>Seamless Onboarding</h4>
        <p>Get started and paid within minutes.</p>
      </div>
    </div>
  </section>
);

export default SettleEasy;
