import { useState } from "react";
import Image from "next/image";
import * as Yup from "yup";
import { ButtonSimpleRounded, InputForm } from "datamynt-ui-library";
import { useFormik } from "formik";
import FormikErrorMsgs from "../formikErrorMsgs";

const Styles = {
  errorMsg: "text-red-primary text-sm"
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(FormikErrorMsgs.invalidEmail)
    .required(FormikErrorMsgs.required),
  name: Yup.string().required(FormikErrorMsgs.required),
  message: Yup.string().required(FormikErrorMsgs.required)
});

const Modal = ({ modalOpened, closeModal }) => {
  const [success, setSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: ""
    },
    validateOnChange: false,
    onSubmit: values => {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          "form-name": "contact_modal",
          ...values
        }).toString()
      }).then(() => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3900);
      });
    },
    validationSchema,
    enableReinitialize: true
  });

  return (
    <div
      id="myModal"
      className={
        modalOpened
          ? "z-50 w-screen h-screen bg-opacity-80 bg-gray-90 absolute px-6"
          : "hidden"
      }
    >
      <div className="flex justify-center items-center">
        <div
          id="modal-content"
          className="rounded-md bg-white flex flex-col relative top-10 p-6 md:w-2/3 py-4"
        >
          <button
            type="button"
            className="w-full flex justify-end text[#231C5E]"
            onClick={closeModal}
          >
            <Image alt="Close" src="/closeIcon.svg" width={24} height={24} />
          </button>
          <div className="space-y-4">
            <h2 className="text-center sm:text-5xl text-3xl">Contact us</h2>
            <p className="text-center">
              Fill up the form and our team will get in touch.
            </p>
            <form
              className="space-y-6 w-full md-w-3/5 md:flex md:flex-col justify-center items-center"
              data-netlify="true"
              name="contact_modal"
              method="POST"
              onSubmit={formik.handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact_modal" />
              <div className="w-full md:w-3/5">
                {!(typeof window === "undefined") && (
                  <>
                    <div>
                      <InputForm
                        onChange={formik.handleChange}
                        highlightTitle
                        placeholder="Full Name*"
                        title="Name"
                        name="name"
                        withBorder
                      />
                      <p
                        className={formik.errors["name"] ? Styles.errorMsg : ""}
                      >
                        {formik.errors["name"]}
                      </p>
                    </div>
                    <div>
                      <InputForm
                        onChange={formik.handleChange}
                        highlightTitle
                        type="email"
                        title="Email"
                        placeholder="Email address*"
                        name="email"
                        withBorder
                      />
                      <p
                        className={
                          formik.errors["email"] ? Styles.errorMsg : ""
                        }
                      >
                        {formik.errors["email"]}
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-col space-y-2 w-full md:w-3/5">
                <label
                  htmlFor="message"
                  className="text-start font-normal text-sm dark:text-white text-black"
                >
                  Notes
                </label>
                <textarea
                  onChange={formik.handleChange}
                  name="message"
                  className="min-h-[125px] border rounded-sm p-2"
                  placeholder="How can we help you?*"
                  id="yourmessage"
                />
                <p className={formik.errors["message"] ? Styles.errorMsg : ""}>
                  {formik.errors["message"]}
                </p>
              </div>
              <div className="w-full md:w-3/5 pb-4 lg:pb-8">
                <ButtonSimpleRounded submitButton label="Send" grow />
                {success && (
                  <p className="text-primary">
                    Your info was sent successfully! We will get in touch
                    shortly.
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
