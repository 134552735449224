import React from "react";
import Image from "next/image";

const Check = () => {
  return (
    <svg
      style={{ minWidth: "25px" }}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.583333"
        y="0.583333"
        width="26.8333"
        height="26.8333"
        rx="5.25"
        fill="url(#paint0_linear_2056_10740)"
        stroke="url(#paint1_linear_2056_10740)"
        strokeWidth="1.16667"
      />
      <path
        d="M9.04102 13.9999L12.3427 17.3016L18.9577 10.6982"
        stroke="#FEFCFF"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2056_10740"
          x1="-18.8114"
          y1="-62.1642"
          x2="23.9338"
          y2="-64.5787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#42E8E0" />
          <stop offset="1" stopColor="#6940DE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2056_10740"
          x1="-18.8114"
          y1="-62.1642"
          x2="23.9338"
          y2="-64.5787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#42E8E0" />
          <stop offset="1" stopColor="#6940DE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Benefits = () => (
  <section
    className={
      "flex flex-col items-center justify-center space-y-12 md:space-y-16 py-24 md:px-12"
    }
  >
    <h4 className="md:text-center md:text-center px-6">
      Simple, Powerful Tools to Help
      <br />
      Your Business Grow
    </h4>

    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-16 md:gap-8 justify-items-center items-center">
      <div className="flex flex-col w-full space-y-12 text-left md:text-left px-6 md:ml-[133px] md:mr-[30px]">
        {[
          {
            header: "API Integration",
            text: "Our API allows you to create seamless branded checkouts and workflows"
          },
          {
            header: "Mobile App & Payments Processing Gateway",
            text: "Including point of sale, subscription / recurring payments or streaming loyalty rewards."
          },
          {
            header: "Exchange and Pay Outs",
            text: "Via bank transfer or mobile money where supported."
          },
          {
            header: "Tax and Accounting Support",
            text: "In addition to automated receipts and sell basis reporting."
          }
        ].map(i => (
          <div key={i.header} className="flex items-start">
            <Check />
            <div className="pl-4">
              <p>
                <strong>{i.header}</strong>
              </p>
              <p>{i.text}</p>
            </div>
          </div>
        ))}
      </div>

      <div
        className={
          "md:hidden w-full overflow-hidden pl-[10%] sm:pl-[30%] h-[370px]"
        }
      >
        <div className={"relative h-full w-[600px]"}>
          <Image alt="Phone" layout={"fill"} src="/systemcrypto.svg" />
        </div>
      </div>

      <div
        className={
          "hidden md:flex relative w-full overflow-hidden h-[370px] md:h-auto:w-auto:px-6"
        }
      >
        <Image
          alt="Phone"
          layout={"fill"}
          objectFit={"contain"}
          src="/systemcrypto.svg"
        />
      </div>
    </div>
  </section>
);

export default Benefits;
