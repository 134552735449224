import React from "react";
import Image from "next/image";

const TopFintech = () => (
  <section id="topFintech">
    <div className="px-8 bg-wrapper text-white flex flex-col items-center justify-center space-y-12 pt-16 pb-16">
      <h4 className="text-center p-8 md:text-center">
        We’ve Been Recognized <br /> For The Work We Do
      </h4>
      <div className="flex flex-row flex-wrap justify-center">
        <Image
          width={400}
          height={450}
          alt="Daily Finance Recognition"
          src="/fintech.svg"
        />
        <Image
          width={400}
          height={450}
          alt="Daily Finance Recognition"
          src="/finance.svg"
        />
        <Image
          width={400}
          height={450}
          alt="Growthlist Recognition"
          src="/growth-list.svg"
        />
      </div>
    </div>
  </section>
);

export default TopFintech;
